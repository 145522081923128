import React from 'react';
// import { Alert } from 'antd';

export default function NotificationBar() {
  // const onClose = () => {
  //   console.log('I was closed.');
  // };

  return <div />;

  // return (
  //   <Alert
  //     className='header-alert-dark'
  //     message=''
  //     type='warning'
  //     closable
  //     onClose={onClose}
  //   />
  // );
}
