import { isExistKey, isError } from './Typeof';

export const isErrorResponse = (response) => {
  return !(
    !isExistKey(response, 'code') ||
    response.code === 0 ||
    response.code === 200
  );
};

export const getResultResponse = (response) => {
  return response;
};

export const getMessageResponse = (response) => {
  if (isError(response)) {
    try {
      return JSON.parse(response.message).message;
    } catch (e) {
      console.log(e);
    }
  }

  if (response && (isErrorResponse(response) || isError(response))) {
    return response.message || '';
  } else {
    return '';
  }
};

export const getErrorsResponse = (response) => {
  const responseData = response?.response || response || {};
  return responseData?.data?.result;
};

export const getCodeResponse = (response) => {
  if (isError(response)) {
    try {
      return JSON.parse(response.message).status;
    } catch (e) {
      console.log(e);
    }
  }

  return response?.code || response?.data?.code;
};
