const API = {
  REFRESH_TOKEN: '/api/v1/public/users/refresh-token',
  AUTH_ME: '/api/v1/me',
  SIGN_IN: '/api/auth/authenticate',
  SIGN_UP: '/api/auth/user/register',
  LOGOUT: '/api/auth/logout',
  MEDIA_UPLOAD: '/api/v1/upload/img',
  FILE_UPLOAD: '/api/v1/upload/file',
  FORGOT_PASSWORD: '/api/auth/reset-password',
  CHANGE_USER_INFO: '/api/v1/user/change-info',
  CHANGE_PASSWORD: '/api/v1/user/change-password',
  PAGE_PERMISSIONS: '/api/v1/admin/permission/get-by-page-name',
  RESET_PASSWORD: '/api/admin/user/create-self-password',
  NOTIFICATION: '/api/notification',
  SYSTEM_CONFIG_ALL: '/api/system-config/all',
  CAMPAIGN: '/api/statistic/campaign',
  CAMPAIGN_SELECT: '/api/campaign/select',
  ADD_NEW_CAMPAIGN: '/api/campaign/store',
  UPDATE_CAMPAIGN: '/api/campaign/update',
  DELETE_CAMPAIGN: '/api/campaign/mass-delete',
  CAMPAIGN_DETAIL: '/api/campaign/detail',
  CHANGE_STATUS_CAMPAIGN: '/api/campaign/change-status',
  AGENCY_SELECT: 'api/agency-advertiser/select',
  AGENCY_ADVERTISER_SELECT: '/api/agency-advertiser/select',
  ADD_NEW_INVENTORY: '/api/inventory/display-ads/store',
  UPDATE_INVENTORY: '/api/inventory/display-ads/update',
  DELETE_INVENTORY: '/api/inventory/display-ads/delete',
  MASS_DELETE_INVENTORY: '/api/inventory/display-ads/mass-delete',
  INVENTORY_DETAIL: '/api/inventory/display-ads/detail',
  SUB_INVENTORY_DETAIL: '/api/channel/detail',
  SUB_INVENTORY_SELECT: '/api/channel/select',
  SUB_INVENTORY_SUGGEST: '/api/channel/suggest',
  LAYOUT_SELECT: '/api/layout/select',
  DELETE_LAYOUT: '/api/layout/delete',
  CREATE_LAYOUT: '/api/layout/store',
  UPDATE_LAYOUT: '/api/layout/update',
  ADD_NEW_DISPLAY_ZONE: '/api/zone/store',
  UPDATE_DISPLAY_ZONE: '/api/zone/update',
  DELETE_DISPLAY_ZONE: '/api/zone/delete',
  MASS_DELETE_DISPLAY_ZONE: '/api/zone/mass-delete',
  ADD_NEW_TAG: '/api/tag/store',
  UPDATE_TAG: '/api/tag/update',
  DELETE_TAG: '/api/tag/delete',
  MASS_DELETE_TAG: '/api/tag/mass-delete',
  INVENTORY_SELECT: '/api/inventory/display-ads/select',
  TAG_SELECT: '/api/tag/select',
  ADD_NEW_SUB_INVENTORY: '/api/channel/store',
  UPDATE_SUB_INVENTORY: '/api/channel/update',
  DELETE_ADS_TYPE: 'api/ads-type/delete',
  MASS_DELETE_ADS_TYPE: 'api/ads-type/mass-delete',
  EXPORT_EXCEL_CONTACT: '/api/v1/admin/contact-request/export/excel',

  DELETED_SUB_INVENTORY: '/api/channel/delete',
  MASS_DELETED_SUB_INVENTORY: '/api/channel/mass-delete',
  CREATE_DISPLAY_TYPE: '/api/ads-type/store',
  UPDATE_DISPLAY_TYPE: '/api/ads-type/update',
  LOCATION_SELECT: '/api/location/select',
  ADS_TYPE_SELECT: '/api/ads-type/select',
  DETAIL_BANNER: '/api/banner/detail',

  ADMIN_USER_APPROVE: 'api/admin/user/approve',
  ADMIN_USER_DELETE: '/api/admin/user/delete',
  ADMIN_USER_LOCKED: '/api/admin/user/locked',
  ADMIN_USER_UNLOCKED: '/api/admin/user/unlocked',
  ADMIN_USER_GET_REPORT_TIME_RANGE: 'api/admin/user/get-report-time-range',
  ADMIN_USER_SET_REPORT_TIME_RANGE: '/api/admin/user/set-report-time-range',
  ADMIN_USER_UPDATE_PASSWORD: '/api/admin/user/update-password',
  ADMIN_USER_GET_ACCESS_DATA: '/api/admin/user/get-access-data',
  ADMIN_USER_SET_ACCESS_DATA: '/api/admin/user/set-access-data',

  BRAND_NAME_SELECT: '/api/brandname/select',
  BUSINESS_ACTIVITY_SELECT: '/api/business-activity/select',
  DELETE_ADS_REDIRECT: '/api/ads/delete',
  CHANGE_STATUS_ADS_REDIRECT: '/api/ads/change-status',

  GET_LIST_ADMIN_ROLE: '/api/v1/admin/role/search',
  CREATE_ADMIN_ROLE: '/api/v1/admin/role',
  UPDATE_ADMIN_ROLE: '/api/v1/admin/role',
  DELETE_ADMIN_ROLE: '/api/v1/admin/role/delete',
  UPDATE_ADMIN_ROLE_PERMISSIONS: '/api/v1/admin/permission-role/add-list',
  GET_ALL_PERMISSIONS: '/api/v1/admin/permission/get-all',

  GET_LIST_ADMIN_PERMISSIONS: '/api/v1/admin/permission/search',
  CREATE_ADMIN_PERMISSION: '/api/v1/admin/permission',
  UPDATE_ADMIN_PERMISSION: '/api/v1/admin/permission',
  DELETE_ADMIN_PERMISSION: '/api/v1/admin/permission/delete',

  // System setting
  GET_LIST_SYSTEM_SETTING: '/api/v1/admin/system-setting/search',
  CREATE_SYSTEM_SETTING: '/api/v1/admin/system-setting',
  UPDATE_SYSTEM_SETTING: '/api/v1/admin/system-setting',
  DELETE_SYSTEM_SETTING: '/api/v1/admin/system-setting/delete',

  //targeting
  CREATE_TARGET_OS: '/api/os/store',
  UPDATE_TARGET_OS: '/api/os/update',
  DELETE_TARGET_OS: '/api/os/delete',
  SELECT_TARGET_OS: '/api/os/select',
  IMPORT_TARGET_OS: '/api/os/import',
  UPLOAD_TARGET_OS: '/api/os/import',

  CREATE_TARGET_DEVICE: '/api/device/store',
  UPDATE_TARGET_DEVICE: '/api/device/update',
  DELETE_TARGET_DEVICE: '/api/device/delete',
  SELECT_TARGET_DEVICE: '/api/device/select',
  UPLOAD_TARGET_DEVICE: '/api/device/import',

  CREATE_TARGET_GENDER: '/api/gender/store',
  UPDATE_TARGET_GENDER: '/api/gender/update',
  DELETE_TARGET_GENDER: '/api/gender/delete',
  SELECT_TARGET_GENDER: '/api/gender/select',

  CREATE_TARGET_LOCATION: '/api/location/store',
  UPDATE_TARGET_LOCATION: '/api/location/update',
  DELETE_TARGET_LOCATION: '/api/location/delete',
  SELECT_TARGET_LOCATION: '/api/location/select',
  UPLOAD_TARGET_LOCATION: '/api/location/import',

  SELECT_TARGET_AGE: '/api/group-age/select',
  CREATE_TARGET_AGE: '/api/group-age/store',
  UPDATE_TARGET_AGE: '/api/group-age/update',
  DELETE_TARGET_AGE: '/api/group-age/delete',

  SELECT_TARGET_CONNECTION_TYPE: '/api/network/select',
  CREATE_TARGET_CONNECTION_TYPE: '/api/network/store',
  UPDATE_TARGET_CONNECTION_TYPE: '/api/network/update',
  DELETE_TARGET_CONNECTION_TYPE: '/api/network/delete',

  //Investor service
  TOGGLE_INVESTOR_STATUS: '/api/v1/admin/investor/change-active',
  UPDATE_INVESTOR: '/api/v1/admin/investor',
  CREATE_INVESTOR: '/api/v1/admin/investor',
  GET_INVESTOR: '/api/v1/admin/investor',
  GET_LIST_USER: '/api/v1/admin/users/search',

  //Region service
  TOGGLE_REGION_STATUS: '/api/v1/admin/area/change-active',
  GET_PROVINCES: '/api/v1/admin/province/search',
  UPDATE_REGION: '/api/v1/admin/area',
  CREATE_REGION: '/api/v1/admin/area',
  GET_DISTRICT: '/api/v1/admin/district/search',

  // QA
  GET_LIST_QA: '/api/v1/admin/question-and-answer/search',
  UPDATE_QA: '/api/v1/admin/question-and-answer',
  CREATE_QA: '/api/v1/admin/question-and-answer/add-one',
  GET_LIST_CATEGORY_QA: '/api/v1/admin/category-question/search',
  DELETE_QA: '/api/v1/admin/question-and-answer/delete',
  UPDATE_CATEGORY_QA: '/api/v1/admin/category-question',
  CREATE_CATEGORY_QA: '/api/v1/admin/category-question',
  DELETE_CATEGORY_QA: '/api/v1/admin/category-question/delete',

  // Report edit project
  GET_LIST_REPORT_EDIT_PROJECT: '/api/v1/admin/report-person/list/search',
  ADD_REPORT_EDIT_PROJECT: '/api/v1/admin/report-person/new',
  UPDATE_PROCESSED_REPORT_EDIT_PROJECT: '/api/v1/admin/report-person/approve',
  REJECT_REPORT_EDIT_PROJECT: '/api/v1/admin/report-person/reject',

  //Project service
  TOGGLE_PROJECT_STATUS: '/api/v1/admin-ambassador/project/change-active',
  PROJECT_TYPE: '/api/v1/public/project-type',
  PROJECT_STATE: '/api/v1/public/project-state',
  GET_PROJECT_UTILS: '/api/v1/public/project-util',
  PUT_UPDATE_PROJECT: '/api/v1/admin/project/:id',
  POST_ADD_PROJECT: '/api/v1/admin/project',
  GET_PROJECT_LIST: '/api/v1/admin/project/search',
  GET_PROJECT_LIST_BY_IDS: '/api/v1/admin-ambassador/project/get-ListIds',
  APPROVE_CHANGE_PROJECTS: '/api/v1/admin/edit-project/approve-list-edit',
  APPROVE_PROJECT: '/api/v1/admin-ambassador/project/approve',
  REJECT_PROJECT: '/api/v1/admin-ambassador/project/reject',

  //edit - project
  GET_LIST_EDIT_PROJECT: '/api/v1/admin/edit-project/search',
  APPROVE_EDIT_PROJECT: '/api/v1/admin/edit-project/approve-edit',

  //News service
  TOGGLE_NEWS_STATUS: '/api/v1/admin/blog/change-status',
  GET_CATEGORY: '/api/v1/admin/blog-category/search',
  SELECT_CATEGORY: '/api/v1/admin/blog-category/select',
  UPDATE_NEW: '/api/v1/admin/blog',
  CREATE_NEW: '/api/v1/admin/blog',

  //User management
  CHANGE_STATUS_USER: '/api/v1/admin/users/update-status',
  CHANGE_ADMIN_ROLE: '/api/v1/admin/users/change-roles',

  //Report management
  CHANGE_STATUS_REPORT: '/api/v1/admin/report/change-active',
  CHANGE_STATUS_PACKAGE: '/api/v1/admin/document-type/change-active',

  //Order service
  GET_DETAIL_ORDER: '/api/v1/admin/order',
  VERIFY_ORDER: '/api/v1/admin/order/change-status',
  GET_TOTAL_REVENUE: '/api/v1/admin/order/get/total-revenue',
  //Report download
  GET_REPORT_DOWNLOAD_DETAIL: '/api/v1/admin/report/downloaded/getAll',
  EXPORT_ORDER_EXCEL: '/api/v1/admin/order/export/excel',
  EXPORT_MOMO_EXCEL: 'api/v1/admin/order-momo/export/excel',

  //Partner
  GET_LIST_PARTNER: '/api/v1/admin/business-partner/list',
  DELETE_PARTNER: '/api/v1/admin/business-partner/delete',
  ADD_PARTNER: '/api/v1/admin/business-partner',
  UPDATE_PARTNER: '/api/v1/admin/business-partner',
};

export default API;

export const AREA_API = {
  GET_LIST_AREA: '/api/v1/public/areas',
  GET_LIST_PROVINCE: '/api/v1/public/provinces',
  GET_lIST_DISTRICT: '/api/v1/public/districts',
};

export const REPORT_API = {
  PUT_UPDATE_PACKAGE: '/api/v1/admin/document-type/:id',
  POST_ADD_PACKAGE: '/api/v1/admin/document-type',
  CHANGE_STATUS_CONTENT: '/api/v1/admin/document/change-active',
  PUT_UPDATE_CONTENT: '/api/v1/admin/document/:id',
  POST_ADD_CONTENT: '/api/v1/admin/document',
  GET_PACKAGE_REPORT: '/api/v1/admin/document-type/search',
  SELECT_PACKAGE_REPORT: '/api/v1/admin/document-type/select',
  GET_REPORT_BY_ID: '/api/v1/admin/report/:id',
  PUT_UPDATE_REPORT: '/api/v1/admin/report',
  POST_ADD_REPORT: '/api/v1/admin/report',
};

export const PROJECT_API = {
  GET_DETAIL_PROJECT: '/api/v1/admin-ambassador/project/:id',
  GET_DETAIL_EDIT_HISTORY: '/api/v1/admin/edit-project/edit-history/detail',
  UPDATE_PROJECT: '/api/v1/admin-ambassador/project/:id',
  POST_CREATE_PROJECT: '/api/v1/admin-ambassador/project',
  UPDATE_HISTORY_EDIT: '/api/v1/admin/edit-project/update-history',
  ADD_HISTORY_EDIT: '/api/v1/admin-ambassador/project/update',
  GET_LIST_PROJECT: '/api/v1/admin-ambassador/project/search',
  SELECT_LIST_PROJECT: '/api/v1/admin-ambassador/project/select',
  GET_LIST_HISTORY_EDIT_PROJECT: '/api/v1/admin/edit-project/get-List',
  UPDATE_lIST_EDIT_PROJECT:
    '/api/v1/admin/edit-project/approve-list-edit-project',
  REJECT_EDIT_HISTORY_PROJECT: '/api/v1/admin/edit-project/reject-edit',
  APPEAL_EDIT_HISTORY_PROJECT: '/api/v1/admin/edit-project/appeal',
};

export const AMBASSADOR_API = {
  GET_LIST_AMBASSADOR: '/api/v1/admin/ambassador/search',
  GET_AMBASSADOR_LEVEL: '/api/v1/public/ambassador-level',
  APPROVE_AMBASSADOR: '/api/v1/admin/ambassador/approve-register',
  UPGRADE_AMBASSADOR: '/api/v1/admin/ambassador/update/level',
  REFUSE_AMBASSADOR: '/api/v1/admin/ambassador/reject-register',
  UPDATE: '/api/v1/admin/ambassador/update/',
  LOCK_AMBASSADOR: '/api/v1/admin/ambassador/lock',
  UNLOCK_AMBASSADOR: '/api/v1/admin/ambassador/unLock',
  PUT_UPDATE_INFO: '/api/v1/admin/ambassador',
  PUT_CHANGE_STATUS_INTERVIEW: '/api/v1/admin/ambassador/interview',
  GET_INFO: '/api/v1/admin/ambassador/profile',
  UPDATE_INFO: '/api/v1/admin/ambassador/profile/edit',
  GET_LIST_REQUEST_UPDATE_INFO:
    '/api/v1/admin/ambassador/update-profile/search',
  APPROVE_UPDATE_INFO: 'api/v1/admin/ambassador/update-profile/approve',
  REJECT_UPDATE_INFO: 'api/v1/admin/ambassador/update-profile/reject',
  GET_UPDATE_INFO_RECENT: '/api/v1/admin/ambassador/recent/profile/update',
};

export const SELECT_API = {
  GET_LIST_INVESTOR: '/api/v1/admin/investor/select',
  GET_LIST_USER: '/api/v1/admin/users/select',
  GET_LIST_AMBASSADOR: '/api/v1/admin/ambassador/select',
  GET_LIST_CATEGORY_QA: '/api/v1/public/question-answer/list-category',
};
