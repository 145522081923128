import React from 'react';

import config from 'src/config/index';

import Icon, { ContactsOutlined } from '@ant-design/icons';
import {
  InvestorIcon,
  ProjectIcon,
  RegionIcon,
  UserGroupIcon,
  UserPermissionIcon,
  ReportDownloadIcon,
  SettingIcon,
  MomoWallet,
  NewsIcon,
  OrderIcon,
  UserIcon,
  ReportMarketIcon,
  AmbassadorPageIcon,
  QAIcon,
  BusinessPartnerIcon,
} from 'src/assets/icon/sidebar';

export const ROUTER_NAME = {
  REGION: 'region',
  MANAGE_PROJECT: 'manage-project',
  APPROVE_PROJECT: 'approve-project',
  HISTORY_PROJECT: 'history-project',
  REPORT_EDIT_PROJECT: 'report-edit-project',
  INVESTOR: 'investor',
  AMBASSADOR_REGISTRATION: 'ambassador-registration',
  AMBASSADOR_LIST: 'ambassador-list',
  AMBASSADOR_UPDATE_PROFILE: 'ambassador-update-profile',
  ORDER: 'order',
  ORDER_MOMO: 'orderMomo',
  REPORT_LIST: 'report-list',
  REPORT_PACKAGE: 'report-package',
  REPORT_CONTENT: 'report-content',
  REPORT_DOWNLOAD_MANAGEMENT: 'report-download-management',
  QA_MANAGEMENT: 'qa-management',
  QA_CATEGORY: 'category-question',
  NEWS: 'news',
  USER_LIST: 'user-list',
  ADMIN_ROLE: 'admin-role',
  ADMIN_PERMISSION: 'admin-permission',
  SYSTEM_SETTING: 'system-setting',
  PROFILE: 'profile',
  CONTACT: 'contact',
  BUSINESS_PARTNER: 'business-partner',
};

const styleIcon = {
  color: 'white',
};
const styleIconColor = {
  color: '#333333',
};
const routesConfig = [
  {
    id: 'market-report-management',
    messageId: 'sidebar.market_report_management',
    type: 'group',
    children: [
      {
        id: ROUTER_NAME.REGION,
        messageId: 'sidebar.region',
        type: 'item',
        icon: <Icon style={styleIcon} component={RegionIcon} />,
        path: config.routes.region,
      },
      {
        id: 'project',
        messageId: 'sidebar.project',
        type: 'collapse',
        icon: <Icon style={styleIcon} component={ProjectIcon} />,
        children: [
          {
            id: ROUTER_NAME.MANAGE_PROJECT,
            messageId: 'sidebar.list_project',
            type: 'item',
            path: config.routes.projectList,
          },
          {
            id: ROUTER_NAME.APPROVE_PROJECT,
            messageId: 'sidebar.approve_project',
            type: 'item',
            path: config.routes.approveProject,
          },
          {
            id: ROUTER_NAME.HISTORY_PROJECT,
            messageId: 'sidebar.history_project',
            type: 'item',
            path: config.routes.projectChange,
          },
          {
            id: ROUTER_NAME.REPORT_EDIT_PROJECT,
            messageId: 'sidebar.report_edit_project',
            type: 'item',
            isPageHidden: false,
            path: config.routes.reportEditProject,
          },
        ],
      },
      {
        id: ROUTER_NAME.INVESTOR,
        messageId: 'sidebar.investor',
        type: 'item',
        icon: <Icon style={styleIcon} component={InvestorIcon} />,
        path: config.routes.investor,
      },
      {
        id: 'ambassador',
        messageId: 'sidebar.ambassador',
        type: 'collapse',
        icon: <Icon style={styleIcon} component={AmbassadorPageIcon} />,
        children: [
          {
            id: ROUTER_NAME.AMBASSADOR_REGISTRATION,
            type: 'item',
            messageId: 'sidebar.ambassador.registration',
            path: config.routes.ambassadorRegistration,
          },
          {
            id: ROUTER_NAME.AMBASSADOR_LIST,
            type: 'item',
            messageId: 'sidebar.ambassador.list',
            path: config.routes.ambassadorList,
          },
          {
            id: ROUTER_NAME.AMBASSADOR_UPDATE_PROFILE,
            type: 'item',
            messageId: 'sidebar.ambassador.update_profile',
            path: config.routes.ambassadorUpdateProfile,
          },
        ],
      },
      {
        id: ROUTER_NAME.ORDER,
        messageId: 'sidebar.order',
        type: 'item',
        icon: <Icon style={styleIcon} component={OrderIcon} />,
        path: config.routes.order,
      },
      {
        id: ROUTER_NAME.ORDER_MOMO,
        messageId: 'sidebar.orderMomo',
        type: 'item',
        icon: <Icon style={styleIcon} component={MomoWallet} />,
        path: config.routes.orderMomo,
      },
      {
        id: 'report-management',
        type: 'collapse',
        messageId: 'sidebar.report_management',
        icon: <Icon style={styleIcon} component={ReportMarketIcon} />,
        children: [
          {
            id: ROUTER_NAME.REPORT_LIST,
            type: 'item',
            messageId: 'sidebar.report_list',
            path: config.routes.reportList,
          },
          {
            id: ROUTER_NAME.REPORT_PACKAGE,
            type: 'item',
            messageId: 'sidebar.report_package',
            path: config.routes.reportPackage,
          },
          {
            id: ROUTER_NAME.REPORT_CONTENT,
            type: 'item',
            messageId: 'sidebar.report_content',
            path: config.routes.reportContent,
          },
        ],
      },
      {
        id: ROUTER_NAME.REPORT_DOWNLOAD_MANAGEMENT,
        messageId: 'sidebar.report_download_management',
        type: 'item',
        icon: <Icon style={styleIcon} component={ReportDownloadIcon} />,
        path: config.routes.reportDownload,
      },
      {
        id: 'qa_management',
        messageId: 'sidebar.qa_management',
        type: 'collapse',
        icon: <Icon style={styleIcon} component={QAIcon} />,
        children: [
          {
            id: ROUTER_NAME.QA_MANAGEMENT,
            type: 'item',
            messageId: 'sidebar.qa_list',
            path: config.routes.qa,
          },
          {
            id: ROUTER_NAME.QA_CATEGORY,
            type: 'item',
            messageId: 'sidebar.qa_category',
            path: config.routes.qa_category,
          },
        ],
      },
    ],
  },
  {
    id: 'news-management',
    messageId: 'sidebar.news_management',
    type: 'group',
    children: [
      {
        id: ROUTER_NAME.NEWS,
        messageId: 'sidebar.news_list',
        type: 'item',
        icon: <Icon style={styleIcon} component={NewsIcon} />,
        path: config.routes.news,
      },
    ],
  },
  {
    id: 'contact-management',
    messageId: 'sidebar.contact_list',
    type: 'group',
    children: [
      {
        id: ROUTER_NAME.CONTACT,
        messageId: 'sidebar.contact_list',
        type: 'item',
        icon: <ContactsOutlined style={styleIconColor} />,
        path: config.routes.contactManage,
      },
    ],
  },
  {
    id: 'business-partner',
    messageId: 'sidebar.partner',
    type: 'group',
    children: [
      {
        id: ROUTER_NAME.BUSINESS_PARTNER,
        messageId: 'sidebar.businessPartner',
        type: 'item',
        icon: <Icon component={BusinessPartnerIcon} />,
        path: config.routes.businessPartner,
      },
    ],
  },
  {
    id: 'user-management',
    messageId: 'sidebar.user_management',
    type: 'group',
    children: [
      {
        id: ROUTER_NAME.USER_LIST,
        type: 'item',
        icon: <Icon style={styleIcon} component={UserIcon} />,
        messageId: 'sidebar.user_list',
        path: config.routes.userManage,
      },
      // {
      //   id: 'user-role-list',
      //   type: 'item',
      //   icon: <IoPersonSharp />,
      //   messageId: 'sidebar.user_role_list',
      //   path: config.routes.userRole,
      // },
      // {
      //   id: 'contact-list',
      //   type: 'item',
      //   icon: <IoPersonSharp />,
      //   messageId: 'sidebar.contact_list',
      //   path: config.routes.contactManage,
      // },
      {
        id: ROUTER_NAME.ADMIN_ROLE,
        type: 'item',
        icon: <Icon style={styleIcon} component={UserGroupIcon} />,
        messageId: 'sidebar.admin_role',
        path: config.routes.adminRole,
      },
      {
        id: ROUTER_NAME.ADMIN_PERMISSION,
        type: 'item',
        icon: <Icon style={styleIcon} component={UserPermissionIcon} />,
        messageId: 'sidebar.admin_permission',
        path: config.routes.adminPermission,
      },
    ],
  },
  {
    id: 'system',
    messageId: 'sidebar.system',
    type: 'group',
    children: [
      {
        id: ROUTER_NAME.SYSTEM_SETTING,
        type: 'item',
        icon: <Icon style={styleIcon} component={SettingIcon} />,
        messageId: 'sidebar.system_setting',
        path: config.routes.systemSetting,
      },
    ],
  },
];

export default routesConfig;
