import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import React from 'react';
import routesConfig from '../../pages/routeConfig';
import { useIntl } from 'react-intl';
import { useSidebarContext } from './AppContextProvider/SidebarContextProvider';
import { checkRouteActive } from 'src/shared/utils/Route';
import { isEmpty } from 'src/shared/utils/Typeof';

function getStyles(item, sidebarColorSet, isSidebarBgImage, index, isGroup) {
  const { pathname } = useLocation();

  if (index === 0 || isGroup) {
    return {
      color: sidebarColorSet.sidebarTextColor,
      // backgroundColor: isSidebarBgImage ? '' : sidebarColorSet.sidebarBgColor,
    };
  } else {
    const isActive = checkRouteActive(pathname, item);

    return {
      color: isActive
        ? sidebarColorSet.sidebarMenuSelectedTextColor
        : sidebarColorSet.sidebarTextColor,
      backgroundColor: isActive
        ? sidebarColorSet.sidebarMenuSelectedBgColor
        : isSidebarBgImage
        ? ''
        : sidebarColorSet.sidebarBgColor,
    };
  }
}

const renderMenuItemChildren = (item) => {
  const { icon, messageId, path } = item;
  const { messages } = useIntl();

  if (path && path.includes('/'))
    return (
      <Link to={path}>
        {icon &&
          (React.isValidElement(icon) ? (
            <span className='ant-menu-item-icon'>{icon}</span>
          ) : (
            <icon className='ant-menu-item-icon' />
          ))}
        <span data-testid={messageId.toLowerCase + '-nav'}>
          {messages[messageId]}
        </span>
      </Link>
    );
  else {
    return (
      <>
        {icon &&
          (React.isValidElement(icon) ? (
            <span className='ant-menu-item-icon'>{icon}</span>
          ) : (
            <icon className='ant-menu-item-icon' />
          ))}
        <span data-testid={messageId.toLowerCase + '-nav'}>
          {messages[messageId]}
        </span>
      </>
    );
  }
};

const renderMenuItem = (item, sidebarColorSet, isSidebarBgImage, index) => {
  return item.type === 'collapse' ? (
    <Menu.SubMenu
      style={getStyles(item, sidebarColorSet, isSidebarBgImage, index, true)}
      key={item.id}
      title={renderMenuItemChildren(item, sidebarColorSet, isSidebarBgImage)}>
      {item.children.map((item) =>
        renderMenuItem(item, sidebarColorSet, isSidebarBgImage, index + 1),
      )}
    </Menu.SubMenu>
  ) : (
    <Menu.Item
      key={item.id}
      style={getStyles(item, sidebarColorSet, isSidebarBgImage, index)}>
      {item.children
        ? item.children
        : renderMenuItemChildren(item, sidebarColorSet, isSidebarBgImage)}
    </Menu.Item>
  );
};

const renderMenu = (item, sidebarColorSet, isSidebarBgImage, index) => {
  switch (item.type) {
    case 'group':
      return (
        <Menu.ItemGroup
          style={getStyles(
            item,
            sidebarColorSet,
            isSidebarBgImage,
            index,
            true,
          )}
          key={item.id}
          title={renderMenuItemChildren(
            item,
            sidebarColorSet,
            isSidebarBgImage,
          )}>
          {item.children.map((item) =>
            renderMenuItem(item, sidebarColorSet, isSidebarBgImage, index + 1),
          )}
        </Menu.ItemGroup>
      );
    case 'collapse':
      return renderMenuItem(item, sidebarColorSet, isSidebarBgImage, index);
    default:
      return (
        <Menu.Item
          key={item.id}
          exact={item.exact}
          style={getStyles(item, sidebarColorSet, isSidebarBgImage, index)}>
          {item.children
            ? item.children
            : renderMenuItemChildren(
                item,
                sidebarColorSet,
                isSidebarBgImage,
                index,
              )}
        </Menu.Item>
      );
  }
};

export const getBreadcrumb = (routes, pathname, breadcrumb = []) => {
  let rs = [];
  routes.forEach((route) => {
    if (!isEmpty(rs)) return;
    switch (route.type) {
      case 'item':
        if (route.path === pathname) {
          rs.push({
            path: route.path,
            title: route.messageId,
          });
        }
        break;
      case 'group':
      case 'collapse': {
        const rsChild = getBreadcrumb(route.children, pathname, [
          { path: route.path, title: route.messageId },
        ]);
        if (!isEmpty(rsChild)) {
          rs.push(...rsChild);
        }
        break;
      }
      default:
    }
  });

  if (isEmpty(rs)) return [];
  return [...breadcrumb, ...rs];
};

export const checkPermissionRoutes = (routes, permissionsList) => {
  if (isEmpty(routes) || isEmpty(permissionsList)) return [];
  const routesShow = [];
  routes.forEach((route) => {
    switch (route.type) {
      case 'item':
        if (
          permissionsList.includes(`${route.id}.view`) &&
          !route.isPageHidden
        ) {
          routesShow.push(route);
        }
        break;
      case 'group':
      case 'collapse': {
        const children = checkPermissionRoutes(route.children, permissionsList);
        if (children?.length > 0) {
          routesShow.push({
            ...route,
            children,
          });
        }
        break;
      }
      default:
    }
  });
  return routesShow;
};

export const getFirstRouteUrl = (routes, permissionsList) => {
  if (isEmpty(routes) || isEmpty(permissionsList)) return;
  let firstRouteUrl;
  routes.some((route) => {
    switch (route.type) {
      case 'item':
        if (permissionsList.includes(`${route.id}.view`)) {
          firstRouteUrl = route?.path;
        }
        break;
      case 'group':
      case 'collapse': {
        const firstRouteUrlChild = getFirstRouteUrl(
          route.children,
          permissionsList,
        );
        if (firstRouteUrlChild) {
          firstRouteUrl = firstRouteUrlChild;
        }
        break;
      }
      default:
    }
    return !!firstRouteUrl;
  });

  return firstRouteUrl;
};

export const getRouteMenus = (permissionsList) => {
  const { sidebarColorSet } = useSidebarContext();
  const { isSidebarBgImage } = useSidebarContext();

  const routes = checkPermissionRoutes(routesConfig, permissionsList);

  return routes.map((route) =>
    renderMenu(route, sidebarColorSet, isSidebarBgImage, 0),
  );
};
