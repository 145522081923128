export const ITEM_PERMISSIONS = {
  ACTION: 'action',
  ADD: 'add',
  DELETE: 'delete',
  SETTING: 'setting',
  UPDATE: 'update',
  HIDDEN: 'hidden',
  EXPORT: 'export',
  REVIEW: 'review',
  VIEW: 'view',
  PAUSE: 'pause',
  STOP: 'stop',
  APPROVE: 'approve',
  REPORT: 'report',
  APPROVE_ALL: 'approve-all',
  RUNNING: 'running',
  LOCKED: 'lock',
  FORCE_UPDATE: 'force-update',
};
