import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { getRouteMenus } from 'src/@crema/utility/VerticalMenuUtils';
import clsx from 'clsx';
import './index.style.less';
import defaultConfig from '../../../../utility/AppContextProvider/defaultConfig';
import { useSidebarContext } from 'src/@crema/utility/AppContextProvider/SidebarContextProvider';
import { MenuStyle } from 'src/shared/constants/AppEnums';
import { useAuthUser } from 'src/@crema/utility/AuthHooks';
import routeConfig from 'src/pages/routeConfig';
import { getOpenKeys } from 'src/shared/utils/Route';

const AppVerticalNav = () => {
  const { menuStyle, sidebarColorSet } = useSidebarContext();
  const { pathname } = useLocation();
  const selectedKeys = getOpenKeys(pathname, routeConfig) || [];
  const defaultOpenKeys = selectedKeys[0];
  const { user } = useAuthUser();
  const userPermissions = user?.permissions || [];

  const [openKeys, setOpenKeys] = useState([defaultOpenKeys]);

  useEffect(() => {
    setOpenKeys([selectedKeys[selectedKeys.length - 2]]);
  }, []);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <Menu
      theme={sidebarColorSet.mode}
      mode='inline'
      className={clsx('app-main-sidebar-menu ', {
        'menu-rounded': menuStyle === MenuStyle.ROUNDED,
        'menu-rounded rounded-menu-reverse':
          menuStyle === MenuStyle.ROUNDED_REVERSE,
        'menu-rounded standard-menu': menuStyle === MenuStyle.STANDARD,
        'menu-rounded curved-menu': menuStyle === MenuStyle.CURVED_MENU,
        'bg-color-menu':
          sidebarColorSet.sidebarBgColor !==
          defaultConfig.sidebar.colorSet.sidebarBgColor,
      })}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      selectedKeys={[selectedKeys[selectedKeys.length - 1]]}>
      {getRouteMenus(userPermissions)}
    </Menu>
  );
};

export default AppVerticalNav;
