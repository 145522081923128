const routes = {
  region: '/region',
  project: '/project',
  detailProject: '/project',
  historyProject: '/history-project',
  approveProject: '/approve-project',
  investor: '/investor',

  projectList: '/project/list',
  reportEditProject: '/report-edit-project',
  projectChange: '/project/change',
  detailProjectChange: '/project/change',
  editHistoryProject: '/project/change/edit',
  detailOriginProject: '/project/detail',

  ambassadorRegistration: '/ambassador/registration',
  ambassadorList: '/ambassador/list',
  ambassadorUpdateProfile: '/ambassador/update-profile',

  order: '/order',
  orderMomo: '/order-momo',

  userManage: '/user-manage/accounts',
  contactManage: '/user-manage/contacts',

  reportList: '/report-manage/list',
  detailReport: '/report-manage/list',
  addReportList: '/report-manage/list/add',
  reportPackage: '/report-manage/package',
  reportContent: '/report-manage/content',

  reportDownload: '/report-download-management',

  businessPartner: '/business-partner',

  qa: '/qa/list',
  qa_category: '/qa/category/list',
  news: '/news',
  adminRole: '/admin/role',
  adminPermission: '/admin/permission',

  systemSetting: '/system-setting',
};

export default routes;
