import { isNumber, isString } from 'lodash';
import moment from 'moment';
export const currentTime = () => Date.now();

export const getDifferentTime = (start, end, prefix = 'days') => {
  if (isString(start) && isString(end)) {
    return moment(end).diff(start, prefix) + 1;
  }
  if (isNumber(start) && isNumber(end)) {
    return moment(end).diff(start, prefix) + 1;
  }
  if (moment.isMoment(start) && moment.isMoment(end)) {
    return end.diff(start, prefix) + 1;
  }
  return 0;
};
