import React, { forwardRef, useImperativeHandle } from 'react';
import { Form } from 'antd';
import { toPairs } from 'lodash';
import { isArray, isString, isEmpty } from 'src/shared/utils/Typeof';
import { KEY_EMPTY_SELECT } from 'src/shared/constants/Default';
const FormContent = forwardRef((props, ref) => {
  const {
    initialValues,
    layout,
    children,

    onFinish: onSave,
    ...attrs
  } = props;

  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    setFields: (fields) => {
      form.setFields(fields);
    },
    setFieldsValue: (values) => {
      form.setFieldsValue(values);
    },
    setFieldValue: (value) => {
      form.setFieldValue(value);
    },
    resetFields: (fields) => {
      form.resetFields(fields);
    },
  }));

  const onSubmit = (data) => {
    const dataSave = {};
    toPairs(data).forEach(([key, value]) => {
      // value empty mặc định select
      if (isString(value) && value?.includes(KEY_EMPTY_SELECT)) {
        return;
      }
      if (key && key.toLowerCase().includes('password')) {
        // password k trim
        dataSave[key] = value;
        return;
      }

      if (isString(value)) {
        dataSave[key] = value.trim();
      } else if (isArray(value)) {
        dataSave[key] = value.filter((f) => !isEmpty(f));
      } else {
        dataSave[key] = value;
      }
    });
    return onSave(dataSave);
  };

  return (
    <Form
      initialValues={initialValues}
      form={form}
      layout={layout}
      onFinish={onSubmit}
      {...attrs}>
      {children}
    </Form>
  );
});

FormContent.propTypes = {};

FormContent.defaultProps = {};

export default FormContent;
