import PropTypes from 'prop-types';
import React from 'react';
import './page_breadcrumb.styles.less';

const SubHeaderAppTemplate = ({ title, subTitle, right }) => {
  return (
    <div className={'page_breadcrumb-wrapper'}>
      <div className='page_breadcrumb-container'>
        <div>{subTitle}</div>
        <h1 className='page_breadcrumb__page-title'>{title}</h1>
      </div>
      {right}
    </div>
  );
};

SubHeaderAppTemplate.propTypes = {
  title: PropTypes.any,
  subTitle: PropTypes.any,
};

SubHeaderAppTemplate.defaultProps = {};

export default SubHeaderAppTemplate;
