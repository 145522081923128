import React from 'react';
import '../AppLogo/index.style.less';
import PropTypes from 'prop-types';
import { useSidebarContext } from '../../../../utility/AppContextProvider/SidebarContextProvider';

const AppLogoWithoutText = ({ hasSidebarColor }) => {
  const { sidebarColorSet } = useSidebarContext();
  return (
    <div className='app-logo'>
      {hasSidebarColor && sidebarColorSet.mode === 'dark' ? (
        <img
          src='https://senvanggroup.com/wp-content/themes/senvanggroup/images/favicon.png'
          alt='crema-logo'
          className='img-no-text'
        />
      ) : (
        <img
          src='https://senvanggroup.com/wp-content/themes/senvanggroup/images/favicon.png'
          alt='crema-logo'
          className='img-no-text'
        />
      )}
    </div>
  );
};

export default AppLogoWithoutText;

AppLogoWithoutText.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
