import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useDispatch } from 'react-redux';
import { withResizeDetector } from 'react-resize-detector';
import { RESIZE_APP_CONTENT } from 'src/shared/constants/ActionTypes';
import { CLASS_NAME_LAYOUT } from 'src/shared/constants/Key';
import { AppSuspense } from '../../index';
import {
  anonymousStructure,
  authorizedStructure,
  unAuthorizedStructure,
} from 'src/pages';
import AppErrorBoundary from '../AppErrorBoundary';
import './index.style.less';
import generateRoutes from '../../utility/RouteGenerator';
import { useAuthUser } from '../../utility/AuthHooks';
import { getFirstRouteUrl } from 'src/@crema/utility/VerticalMenuUtils';
import routesConfig from 'src/pages/routeConfig';
import { logout } from 'src/@crema/services/Application/AuthenStorage';

const { Content } = Layout;

// eslint-disable-next-line react/prop-types
const AppContentView = ({ width, height }) => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useAuthUser();
  const initialUrl = getFirstRouteUrl(routesConfig, user?.permissions);
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch({ type: RESIZE_APP_CONTENT, payload: { width, height } });
    }, 150);

    return () => {
      clearTimeout(timeout);
    };
  }, [width, height]);

  useEffect(() => {
    if (
      !initialUrl &&
      isAuthenticated &&
      window?.location?.pathname !== '/signin'
    ) {
      logout();
    }
  }, [initialUrl]);

  return (
    <div className={'main-content-view-wrapper'}>
      <Content className={CLASS_NAME_LAYOUT}>
        <AppSuspense>
          <AppErrorBoundary>
            {generateRoutes(
              {
                initialUrl,
                isAuthenticated: isAuthenticated,
                unAuthorizedStructure,
                authorizedStructure,
                anonymousStructure,
              },
              user?.permissions,
            )}
          </AppErrorBoundary>
        </AppSuspense>
      </Content>
    </div>
  );
};

export default withResizeDetector(AppContentView);
