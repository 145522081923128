import React from 'react';
import config from 'src/config/index';
import { ROUTER_NAME } from 'src/pages/routeConfig';

const DetailEditHistory = React.lazy(() =>
  import('src/pages/Project/Change/DetailEditHistory'),
);
const QAPage = React.lazy(() => import('src/pages/QA'));
const QACategoryPage = React.lazy(() => import('src/pages/QA/Category'));
const ReportEditProjectPage = React.lazy(() =>
  import('src/pages/ReportEditProject'),
);
const DetailOriginProject = React.lazy(() =>
  import('src/pages/ReportEditProject/DetailOriginProject'),
);

const ContactManage = React.lazy(() =>
  import('src/pages/UserManage/ContactManage'),
);

const ReportDownloadManagement = React.lazy(() =>
  import('src/pages/ReportDownload'),
);
const OrderManagement = React.lazy(() => import('src/pages/Order'));
const OrderMomo = React.lazy(() => import('src/pages/OrderMomo'));
const ProjectList = React.lazy(() => import('src/pages/Project/List'));
const ListWaitingApproval = React.lazy(() =>
  import('src/pages/Project/ListWaitingApproval'),
);
const ProjectChange = React.lazy(() => import('src/pages/Project/Change'));
const InvestorManagement = React.lazy(() => import('src/pages/Investor'));
const RegionManagement = React.lazy(() => import('src/pages/Region'));
const EditProject = React.lazy(() => import('src/pages/Project/EditProject'));

const CreateProject = React.lazy(() => import('src/pages/Project/AddProject'));
const DetailProject = React.lazy(() =>
  import('src/pages/Project/Change/DetailProject'),
);
const EditHistoryChangeProject = React.lazy(() =>
  import('src/pages/Project/Change/EditHistoryChange'),
);
const ReportList = React.lazy(() => import('src/pages/ReportMange/ReportList'));

const AddReport = React.lazy(() =>
  import('src/pages/ReportMange/ReportList/AddReport'),
);
const EditReport = React.lazy(() =>
  import('src/pages/ReportMange/ReportList/EditReport'),
);
const ReportPackage = React.lazy(() =>
  import('src/pages/ReportMange/ReportPackage'),
);
const ReportContent = React.lazy(() =>
  import('src/pages/ReportMange/ReportContent'),
);

const AllNews = React.lazy(() => import('../NewsManagement/All'));
const AdminUser = React.lazy(() => import('../Admin/AdminUser'));
const AdminRole = React.lazy(() => import('../Admin/AdminRole'));
const SystemSetting = React.lazy(() => import('../Admin/SystemSetting'));

const AdminPermissions = React.lazy(() => import('../Admin/AdminPermissions'));
const AmbassadorRegistration = React.lazy(() =>
  import('../Ambassador/Register'),
);
const AmbassadorList = React.lazy(() => import('../Ambassador/List'));
const AmbassadorUpdateProfile = React.lazy(() =>
  import('../Ambassador/AmbassadorUpdateProfile'),
);

const BusinessPartner = React.lazy(() => import('src/pages/BusinessPartner'));

// name must be match with id in routerConfig.js

export const samplePagesConfigs = [
  {
    path: config.routes.projectList,
    element: <ProjectList />,
    name: ROUTER_NAME.MANAGE_PROJECT,
  },
  {
    path: config.routes.approveProject,
    element: <ListWaitingApproval />,
    name: ROUTER_NAME.APPROVE_PROJECT,
  },
  {
    path: config.routes.projectChange,
    element: <ProjectChange />,
    name: ROUTER_NAME.HISTORY_PROJECT,
  },
  {
    path: `${config.routes.detailProjectChange}/:id`,
    element: <DetailEditHistory />,
    name: ROUTER_NAME.HISTORY_PROJECT,
  },
  {
    path: `${config.routes.editHistoryProject}/:id`,
    element: <EditHistoryChangeProject />,
    name: ROUTER_NAME.HISTORY_PROJECT,
  },
  {
    path: `${config.routes.detailOriginProject}/:id`,
    element: <DetailProject />,
    name: ROUTER_NAME.MANAGE_PROJECT,
  },
  {
    path: `${config.routes.reportEditProject}/:id`,
    element: <DetailOriginProject />,
    name: ROUTER_NAME.REPORT_EDIT_PROJECT,
  },

  {
    path: `${config.routes.detailProject}/add`,
    element: <CreateProject />,
    name: ROUTER_NAME.MANAGE_PROJECT,
  },
  {
    path: `${config.routes.detailProject}/:id`,
    element: <EditProject />,
    name: ROUTER_NAME.MANAGE_PROJECT,
  },
  {
    path: config.routes.reportDownload,
    element: <ReportDownloadManagement />,
    name: ROUTER_NAME.REPORT_DOWNLOAD_MANAGEMENT,
  },
  {
    path: config.routes.order,
    element: <OrderManagement />,
    name: ROUTER_NAME.ORDER,
  },
  {
    path: config.routes.orderMomo,
    element: <OrderMomo />,
    name: ROUTER_NAME.ORDER_MOMO,
  },
  {
    path: config.routes.region,
    element: <RegionManagement />,
    name: ROUTER_NAME.REGION,
  },
  {
    path: config.routes.qa,
    element: <QAPage />,
    name: ROUTER_NAME.QA_MANAGEMENT,
  },
  {
    path: config.routes.qa_category,
    element: <QACategoryPage />,
    name: ROUTER_NAME.QA_CATEGORY,
  },
  {
    path: config.routes.reportEditProject,
    element: <ReportEditProjectPage />,
    name: ROUTER_NAME.REPORT_EDIT_PROJECT,
  },
  {
    path: config.routes.news,
    element: <AllNews />,
    name: ROUTER_NAME.NEWS,
  },
  {
    path: config.routes.contactManage,
    element: <ContactManage />,
    name: ROUTER_NAME.CONTACT,
  },
  {
    path: config.routes.investor,
    element: <InvestorManagement />,
    name: ROUTER_NAME.INVESTOR,
  },

  //* Report management
  {
    path: config.routes.reportList,
    element: <ReportList />,
    name: ROUTER_NAME.REPORT_LIST,
  },
  {
    path: config.routes.addReportList,
    element: <AddReport />,
    name: ROUTER_NAME.REPORT_LIST,
  },
  {
    path: `${config.routes.detailReport}/:id`,
    element: <EditReport />,
    name: ROUTER_NAME.REPORT_LIST,
  },
  {
    path: config.routes.reportPackage,
    element: <ReportPackage />,
    name: ROUTER_NAME.REPORT_PACKAGE,
  },
  {
    path: config.routes.reportContent,
    element: <ReportContent />,
    name: ROUTER_NAME.REPORT_CONTENT,
  },

  //User management
  {
    path: config.routes.userManage,
    element: <AdminUser />,
    name: ROUTER_NAME.USER_LIST,
  },
  {
    path: config.routes.adminRole,
    element: <AdminRole />,
    name: ROUTER_NAME.ADMIN_ROLE,
  },
  {
    path: config.routes.adminPermission,
    element: <AdminPermissions />,
    name: ROUTER_NAME.ADMIN_PERMISSION,
  },
  {
    path: config.routes.systemSetting,
    element: <SystemSetting />,
    name: ROUTER_NAME.SYSTEM_SETTING,
  },

  // business partner
  {
    path: config.routes.businessPartner,
    element: <BusinessPartner />,
    name: ROUTER_NAME.BUSINESS_PARTNER,
  },

  //Ambassador
  {
    path: config.routes.ambassadorRegistration,
    element: <AmbassadorRegistration />,
    name: ROUTER_NAME.AMBASSADOR_REGISTRATION,
  },
  {
    path: config.routes.ambassadorList,
    element: <AmbassadorList />,
    name: ROUTER_NAME.AMBASSADOR_LIST,
  },
  {
    path: config.routes.ambassadorUpdateProfile,
    element: <AmbassadorUpdateProfile />,
    name: ROUTER_NAME.AMBASSADOR_UPDATE_PROFILE,
  },
];
