import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Avatar, Dropdown } from 'antd';
import { FaChevronDown } from 'react-icons/fa';
import './index.style.less';
import { useThemeContext } from 'src/@crema/utility/AppContextProvider/ThemeContextProvider';
import { useAuthMethod, useAuthUser } from 'src/@crema/utility/AuthHooks';
import { useSidebarContext } from 'src/@crema/utility/AppContextProvider/SidebarContextProvider';
import PropTypes from 'prop-types';

const UserInfo = ({ hasColor }) => {
  const { themeMode } = useThemeContext();
  const { logout } = useAuthMethod();
  const { user } = useAuthUser();
  const navigate = useNavigate();
  const { sidebarColorSet } = useSidebarContext();
  const { isSidebarBgImage } = useSidebarContext();

  const getUserAvatar = () => {
    if (user?.displayName) {
      return user?.displayName.charAt(0).toUpperCase();
    }
    if (user?.email) {
      return user?.email.charAt(0).toUpperCase();
    }
  };

  // const menu = (
  //   <Menu className='dropdown-list'>
  //     <Menu.Item onClick={() => navigate('/my-profile')}>My Profile</Menu.Item>
  //     <Menu.Item onClick={() => logout()}>Logout</Menu.Item>
  //   </Menu>
  // );

  const items = [
    {
      label: (
        <div onClick={() => navigate('/my-profile')}>Thông tin cá nhân</div>
      ),
      key: 'user_info-1',
    },
    {
      label: <div onClick={() => logout()}>Đăng xuất</div>,
      key: 'user_info-1',
    },
  ];

  return (
    <>
      {hasColor ? (
        <div
          style={{
            backgroundColor: isSidebarBgImage
              ? ''
              : sidebarColorSet.sidebarHeaderColor,
            color: sidebarColorSet.sidebarTextColor,
          }}
          className={clsx('cr-user-info cr-user-info-hasColor', {
            light: themeMode === 'light',
          })}>
          <Dropdown
            className='user-profile-dropdown'
            overlayClassName='dropdown-list'
            menu={{ items }}
            trigger={['click']}
            placement='bottomRight'
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
            }}>
            <a className='cr-user-info-inner ant-dropdown-link'>
              {user?.photoURL ? (
                <Avatar className='cr-user-info-avatar' src={user?.photoURL} />
              ) : (
                <Avatar className='cr-user-info-avatar'>
                  {getUserAvatar()}
                </Avatar>
              )}
              <span className='cr-user-info-content'>
                <span className='cr-user-name-info'>
                  <h3
                    className={clsx('cr-user-name text-truncate', {
                      light: themeMode === 'light',
                    })}>
                    {user?.displayName ? user?.displayName : 'admin user '}
                  </h3>
                  <span className='cr-user-arrow'>
                    <FaChevronDown />
                  </span>
                </span>
                <span className='cr-user-designation text-truncate'>
                  ID: {user?.id}
                </span>
              </span>
            </a>
          </Dropdown>
        </div>
      ) : (
        <div
          className={clsx('cr-user-info', {
            light: themeMode === 'light',
          })}>
          <Dropdown
            className='user-profile-dropdown'
            overlayClassName='dropdown-list'
            menu={{ items }}
            trigger={['click']}
            placement='bottomRight'
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
            }}>
            <a className='cr-user-info-inner ant-dropdown-link'>
              {user?.photoURL ? (
                <Avatar className='cr-user-info-avatar' src={user?.photoURL} />
              ) : (
                <Avatar className='cr-user-info-avatar'>
                  {getUserAvatar()}
                </Avatar>
              )}
              <span className='cr-user-info-content'>
                <span className='cr-user-name-info'>
                  <h3
                    className={clsx('cr-user-name text-truncate', {
                      light: themeMode === 'light',
                    })}>
                    {user?.displayName ? user?.displayName : 'admin user '}
                  </h3>
                </span>
                <span className='cr-user-designation text-truncate'>
                  ID: {user?.id}
                </span>
              </span>
            </a>
          </Dropdown>
        </div>
      )}
    </>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  hasColor: PropTypes.bool,
};
