import React from 'react';
import { Layout } from 'antd';
import './index.style.less';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import PropTypes from 'prop-types';
// import { useIntl } from 'react-intl';
// import AppLanguageSwitcher from '../../AppLanguageSwitcher';
// import AppHeaderMessages from '../../AppHeaderMessages';
// import AppNotifications from '../../AppNotifications';
// import { FiMoreVertical } from 'react-icons/fi';
import UserInfo from '../components/UserInfoMiniToggle';
import { REACT_APP_CLIENT_URL } from 'src/shared/constants/serverConfig';
import AppIconButton from 'src/@crema/core/AppIconButton';
// import UserBalance from '../components/UserInfoMiniToggle/UserBalance';
// import ButtonOutline from 'src/@crema/component/ButtonOutline';

const AppHeader = ({ isCollapsed, onToggleSidebar }) => {
  const { Header } = Layout;
  // const { Search } = Input;
  // const { messages } = useIntl();

  // const menuMobile = (
  //   <Menu>
  //     {/* <AppHeaderMessages /> */}
  //     <AppNotifications />
  //     <AppLanguageSwitcher />
  //   </Menu>
  // );

  // const items = [
  //   {
  //     label: <AppNotifications />,
  //     key: 'app_header-1',
  //   },
  //   {
  //     label: <AppLanguageSwitcher />,
  //     key: 'app_header-2',
  //   },
  // ];

  return (
    <Header className='app-header-mini-sidebar'>
      <div className='app-header-mini-sidebar--action'>
        {React.createElement(
          isCollapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold,
          {
            className: 'trigger',
            onClick: onToggleSidebar,
          },
        )}
      </div>
      {/* <Search
        className='header-search-mini-sidebar'
        placeholder={messages['common.searchHere']}
      /> */}
      <AppIconButton
        className='auth-page-layout__header-btn'
        shape='round'
        onClick={() => (window.location.href = REACT_APP_CLIENT_URL)}>
        Cổng thông tin
      </AppIconButton>
      <div className='app-header-mini-sidebar__user-info'>
        {/*<Button size={'middle'} shape='round' type='primary'>*/}
        {/*  {messages['header.deposit']}*/}
        {/*</Button>*/}
        {/*<UserBalance />*/}
        {/*<div className='app-header-mini-sidebar__user-info-dash' />*/}
        <UserInfo />
      </div>

      {/*<div className='app-header-mini-sidebar-sectionDesktop'>*/}
      {/*<AppLanguageSwitcher />*/}
      {/* <AppHeaderMessages /> */}
      {/*<AppNotifications />*/}
      {/*</div>*/}
      <div className='app-header-mini-sidebar-section-mobile'>
        {/*<Dropdown menu={{ items }} trigger={['click']}>*/}
        {/*  <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>*/}
        {/*    <FiMoreVertical />*/}
        {/*  </a>*/}
        {/*</Dropdown>*/}
      </div>
    </Header>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  isCollapsed: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
};
